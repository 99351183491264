import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useLocation, NavLink as RouterLink } from 'react-router-dom';

// @mui
import { Link, Box } from '@mui/material';
import Main from './Main';

// // hooks
// import useResponsive from '../../hooks/useResponsive';
// // components
// import { useSettingsContext } from '../../components/settings';
// //
// import Main from './Main';
// import Header from './header';
// import NavMini from './nav/NavMini';
// import NavVertical from './nav/NavVertical';
// import NavHorizontal from './nav/NavHorizontal';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const navigate = useNavigate();
  const location = useLocation();

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const [currentPage, setCurrentPage] = useState<string>(location.pathname.split('/')[2]);

  const HandleHref = (href: string) => {
    setCurrentPage(href);
  };

  const HandleSideBar = () => {
    document.body.classList.toggle('sb-sidenav-toggled');
    localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled').toString());
  };

  return (
    <>
      <div className='sb-nav-fixed'>
        <nav className='sb-topnav navbar navbar-expand navbar-dark'>
          <a className='navbar-brand ps-3' onClick={() => navigate('/main/dashboard')}>
            PIGFARM
          </a>
          <button
            className='btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0'
            id='sidebarToggle'
            onClick={HandleSideBar}
          >
            <i className='fas fa-bars'></i>
          </button>
          <div className='d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0'>
            <a className='nav-link' href='#' role='button' aria-expanded='false'>
              <i className='fas fa-user fa-fw'></i> 기관관리자
            </a>
          </div>

          <div className=' ms-auto ms-md-0 me-3 me-lg-4'>
            <button className='nav-link' onClick={() => navigate('/auth/login')}>
              <i className='fas fa-sign-in-alt fa-fw'></i> 로그아웃
            </button>
          </div>
        </nav>
        <div id='layoutSidenav'>
          <div id='layoutSidenav_nav'>
            <nav className='sb-sidenav accordion sb-sidenav-dark' id='sidenavAccordion'>
              <div className='sb-sidenav-menu'>
                <div className='nav'>
                  <Link
                    key='Dashboard'
                    noWrap
                    underline='none'
                    component={RouterLink}
                    to='/main/dashboard'
                    onClick={() => HandleHref('dashboard')}
                  >
                    <div className={currentPage === 'dashboard' ? 'nav-link active' : 'nav-link'}>
                      <div className='sb-nav-link-icon'>
                        <i className='fas fa-desktop'></i>
                      </div>
                      대시보드
                    </div>
                  </Link>
                  <Link
                    key='user'
                    noWrap
                    underline='none'
                    component={RouterLink}
                    to='/main/user'
                    onClick={() => HandleHref('user')}
                  >
                    <div className={currentPage === 'user' ? 'nav-link active' : 'nav-link'}>
                      <div className='sb-nav-link-icon'>
                        <i className='fas fa-user-friends'></i>
                      </div>
                      사용자 조회
                    </div>
                  </Link>
                  <Link
                    key='farmer'
                    noWrap
                    underline='none'
                    component={RouterLink}
                    to='/main/farmer'
                    onClick={() => HandleHref('farmer')}
                  >
                    <div className={currentPage === 'farmer' ? 'nav-link active' : 'nav-link'}>
                      <div className='sb-nav-link-icon'>
                        <i className='fas fa-user-friends'></i>
                      </div>
                      농장주 조회
                    </div>
                  </Link>
                  <Link
                    key='farm'
                    noWrap
                    underline='none'
                    component={RouterLink}
                    to='/main/farm'
                    onClick={() => HandleHref('farm')}
                  >
                    <div className={currentPage === 'farm' ? 'nav-link active' : 'nav-link'}>
                      <div className='sb-nav-link-icon'>
                        <i className='fas fa-home'></i>
                      </div>
                      농장 조회
                    </div>
                  </Link>
                  <Link
                    key='funding'
                    noWrap
                    underline='none'
                    component={RouterLink}
                    to='/main/funding'
                    onClick={() => HandleHref('funding')}
                  >
                    <div className={currentPage === 'funding' ? 'nav-link active' : 'nav-link'}>
                      <div className='sb-nav-link-icon'>
                        <i className='fas fa-chart-line'></i>
                      </div>
                      펀딩 조회
                    </div>
                  </Link>
                  <Link
                    key='pig'
                    noWrap
                    underline='none'
                    component={RouterLink}
                    to='/main/pig'
                    onClick={() => HandleHref('pig')}
                  >
                    <div className={currentPage === 'pig' ? 'nav-link active' : 'nav-link'}>
                      <div className='sb-nav-link-icon'>
                        <i className='fas fa-table'></i>
                      </div>
                      돼지 조회
                    </div>
                  </Link>
                  <Link
                    key='ASF'
                    noWrap
                    underline='none'
                    component={RouterLink}
                    to='/main/ASFMap'
                    onClick={() => HandleHref('map')}
                  >
                    <div className={currentPage === 'map' ? 'nav-link active' : 'nav-link'}>
                      <div className='sb-nav-link-icon'>
                        <i className='fas fa-map'></i>
                      </div>
                      ASF 현황 조회
                    </div>
                  </Link>
                </div>
              </div>
            </nav>
          </div>
          <div id='layoutSidenav_content'>
            <Main>
              <Outlet />
            </Main>
            <footer className='py-4 bg-light mt-auto'>
              <div className='container-fluid px-4'>
                <div className='d-flex align-items-center justify-content-between small'>
                  <div className='text-muted'>Copyright &copy; FIGFARM 2023</div>
                  <div>
                    <a href='#'>Privacy Policy</a>
                    &middot;
                    <a href='#'>Terms &amp; Conditions</a>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </>
  );

  // const renderNavVertical = <NavVertical openNav={open} onCloseNav={handleClose} />;

  // if (isNavHorizontal) {
  //   return (
  //     <>
  //       <Header onOpenNav={handleOpen} />

  //       {isDesktop ? <NavHorizontal /> : renderNavVertical}

  //       <Main>
  //         <Outlet />
  //       </Main>
  //     </>
  //   );
  // }

  // if (isNavMini) {
  //   return (
  //     <>
  //       <Header onOpenNav={handleOpen} />

  //       <Box
  //         sx={{
  //           display: { lg: 'flex' },
  //           minHeight: { lg: 1 },
  //         }}
  //       >
  //         {isDesktop ? <NavMini /> : renderNavVertical}

  //         <Main>
  //           <Outlet />
  //         </Main>
  //       </Box>
  //     </>
  //   );
  // }

  // return (
  //   <>
  //     <Header onOpenNav={handleOpen} />

  //     <Box
  //       sx={{
  //         display: { lg: 'flex' },
  //         minHeight: { lg: 1 },
  //       }}
  //     >
  //       {renderNavVertical}

  //       <Main>
  //         <Outlet />
  //       </Main>
  //     </Box>
  //   </>
  // );
}
