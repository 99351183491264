import React from "react";
import { Route, Routes } from "react-router-dom";

import Router from "./routes";

import "./App.css";
import "./css/font.css";
import "./css/styles.css";
import "./css/custom.css";
import "./css/fonts/fontawesome/css/fontawesome-all.min.css";

function App() {
  return <Router />;
}

export default App;
